import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TestimonialForOurPurpose } from "../Domain/Testimonial";
import { getTestimonialsForOurPurpose } from "../Clients/BeTheChangeApi";
import TestimonialCard from "./TestimonialCard";

const MainGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2em"
});

const WordBlock = styled(Grid)({
  paddingBottom: "1em",
});

const OurPurpose: React.FC = () => {
  const [testimonials, setTestimonials] = useState<TestimonialForOurPurpose[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getTestimonialsForOurPurpose().then(testimonialList => {
      setTestimonials(testimonialList.filter(t => t.webVisibilityStatus === true));
      setIsLoading(false);
    });
  }, []);

  return (
    <MainGrid container>
      <Grid item xs={12}>
        <h1>Our Purpose</h1>
      </Grid>
      {isLoading && <CircularProgress />}
      {!isLoading && <Grid container>
          <WordBlock item xs={12}>At Be The Change Gulf Coast, we believe in the power of small acts creating a big impact. Just like honeybees working together to build a thriving hive, each of us can play a role in lifting up our community.</WordBlock>
          <WordBlock item xs={12}>These testimonials share the stories of real people whose lives have been touched by kindness and generosity. By working together, we can ensure that no one has to face impossible choices alone. Whether it’s buying medicine or putting food on the table, your support helps families overcome life’s unexpected challenges.</WordBlock>
          <WordBlock item xs={12}>Join our hive and be a part of something greater. Together, we can make a difference—one small step at a time.</WordBlock>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            {testimonials && testimonials.map(testimonial =>
              <Grid item xs={12} md={6} style={{paddingTop:"1vh"}} key={testimonial.organizationName}>
                <TestimonialCard statement={testimonial.statement} logoUrl={testimonial.logo ? URL.createObjectURL(testimonial.logo) : null} organizationName={testimonial.organizationName} representativeName={testimonial.representativeName} dateEntered={new Date(testimonial.dateCreated)}/>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>}
    </MainGrid>
  );
};

export default OurPurpose;
